import React from "react";
import * as dc from "dc";
import { ChartTemplate } from "../charttemplate";

const pieChartFn = (props) => {
    const val = props;
    return (divRef, ndx) => {
        const el = divRef;
        
        if(!el.chart) {
          if(ndx) {
            el.chart = new dc.PieChart(el);
            let dim = ndx.dimension(val.field, val.isArray);
            el.chart.width(el.width)
                 .height(el.height)
                 .minHeight(50)
                 .minWidth(50)
                 .radius(el.width)
                 .dimension(dim)
                 .minAngleForLabel(0)
                 .group(dim.group());

            el.chart.render();
            new ResizeObserver(() => {
              el.chart.width(el.width)
                   .height(el.height)
                   .redraw();
            }).observe(el);
          }
        }

        if(el.chart && val.colors) {
          el.chart.ordinalColors(val.colors);
          el.chart.redraw();
        }

        return el.chart // Return the chart object to the template component
    }
}

export const PieChart = props => (
    /*
        Call the ChartTemplate Component with the chartFunction
    */
      
    <ChartTemplate chartFunction={pieChartFn(props)} {...props.styles} className={props.className} />
)