import React from "react";
import { CXContext } from "../contexts/contexts.js";

export const ProjectTable = props => {
    /*
    We render the dc chart using an effect. We want to pass the chart as a prop after the dc call,
    but there is nothing by default to trigger a re-render and the prop, by default would be undefined.
    To solve this, we hold a state key and increment it after the effect ran. 
    By passing the key to the parent div, we get a rerender once the chart is defined. 
    */

  const context = React.useContext(CXContext);
  const ndx = context.ndx;
  const [records, setRecordsOnUpdate] = React.useState(ndx.allFiltered());

  React.useEffect(() => {
    ndx.onChange(() => {
      setRecordsOnUpdate(ndx.allFiltered());
    });
  }, [ndx]);

  return (
    <div
      {...props.styles}
      className={props.className + ' overflow-auto'}
    >
      {records.map(record => (
        <div className="flex flex-wrap mb-px align-middle bg-white dark:bg-white/10 leading-tight p-2 exp-item" key={record.id}>
          <div className="w-2/4 p-1">
            <div className="text-xl font-bold">{record.name}</div>
          </div>
          <div className="w-1/4 p-1">{record.org}</div>
          <div className="w-1/4 p-1">{record.dateDisplay}</div>
          <div className="w-1/4 p-1">
            <div className="text-gray-600 dark:text-gray-400">{record.roles.join(', ')}</div>
          </div>
          <div className="w-3/4 p-1">
            <div className="text-gray-600 dark:text-gray-400 text-sm">{record.skills.join(', ')}</div>
          </div>
          <div className="w-full m-2">{record.description}</div>
          {record.awards.length > 0 && <div className="w-full p-1 text-green-700 dark:text-green-400">
            <div className="font-bold">Awards</div>
            {record.awards.map(award => (
              <div className="w-full flex flex-wrap mx-2" key={award.name + award.year}>
                <div className="flex-1 p-1">{award.year} - {award.name}</div>
                <div className="flex-1 p-1">{award.description}</div>
              </div>
            ))}
          </div>}
        </div>
      ))}
    </div>
  );
}
