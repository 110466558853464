import React from "react";
import crossfilter from "crossfilter2";
import exp from "./expdata.js";
import { CXContext } from "../contexts/contexts.js";

export class DataContext extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      loading:false,
      hasNDX:false
    };
  }

  componentDidMount() {
      if (this.state.hasNDX){
          return;
      }
      if(this.state.loading){
          return;
      }
      // this.setState({loading:true})

      this.ndx = crossfilter(exp.projects);
      this.setState({loading:false,hasNDX:true});
  }

  render() {
      if(!this.state.hasNDX){
          return null;
      }
    return (
      <CXContext.Provider value={{ndx:this.ndx}}>
        <div ref={this.parent}>
        {this.props.children} {/*Render The charts*/}
        </div>
      </CXContext.Provider>
    );
  }
}
