import * as React from 'react';
import * as d3 from 'd3';
import {Responsive, WidthProvider} from "react-grid-layout";
import Layout from '../components/layout.js';
import { RowChart } from '../components/charts/rowchart.js';
import { DataContext } from '../components/datacontext.js';
import { PieChart } from '../components/charts/piechart.js';
import { BarChart } from '../components/charts/barchart.js';
import { ProjectTable } from '../components/projecttable.js';

import "dc/dist/style/dc.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const InteractiveResumePage = () => {
  // const resumeBlockStyle = "flex flex-wrap mb-px align-middle bg-white dark:bg-black leading-tight p-2 exp-item rounded"
  const chartTitleStyle = 'absolute -top-3 font-bold';
  return (
    <Layout pagePath="/interactiveresume">
      <DataContext>
        <ResponsiveGridLayout
          className="layout"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 16, md: 12, sm: 8, xs: 4, xxs: 2 }} 
          rowHeight={30}
          draggableCancel='.pie-slice-group,.pie-label-group,.row,.brush,.p-1,.m-2'
        >
          <div key="a" data-grid={{x: 0, y: 0, w: 3, h: 6}}>
            <span className={chartTitleStyle}>Organization</span>
            <PieChart field='org' colors={['#1f77b4', '#6856cd', '#E57200', 'rgb(44, 160, 44)', '#000']} className="w-full h-full"/>
          </div>
          <div key="b" data-grid={{x: 0, y: 4, w: 3, h: 6}}>
            <span className={chartTitleStyle}>Role</span>
            <PieChart field='roles' isArray={true} colors={d3.schemeCategory10} className="w-full h-full"/>
          </div>
          <div key="c" data-grid={{x: 3, y: 0, w: 3, h: 12}}>
            <span className={chartTitleStyle}>Skills</span>
            <RowChart field='skills' isArray={true} minHeight='500' colors={['#bf8335']} className="w-full h-full"/>
          </div>
          <div key="d" data-grid={{x: 6, y: 0, w: 3, h: 12}}>
            <span className={chartTitleStyle}>Libraries</span>
            <RowChart field='libraries' isArray={true} minHeight='500' colors={['#9573cf']} className="w-full h-full"/>
          </div>
          <div key="e" data-grid={{x: 9, y: 1, w: 7, h: 18}}>
            <ProjectTable className="w-full h-full"/>
          </div>
          <div key="f" data-grid={{x: 0, y: 6, w: 9, h: 6}}>
            <BarChart field='endDate' className="w-full h-full"/>
          </div>
        </ResponsiveGridLayout>
      </DataContext>
    </Layout>
  )
}

export default InteractiveResumePage;
