import React from "react";
import * as dc from "dc";
import * as d3 from "d3";
import { ChartTemplate } from "../charttemplate";

const barChartFn = (props) => {
    const val = props;
    return (divRef, ndx) => {
        const el = divRef;
        
        if(!el.chart) {
          if(ndx) {
            el.chart = new dc.BarChart(el);
            let dim = ndx.dimension(val.field, val.isArray);
            el.chart.width(el.width)
                 .height(el.clientHeight-20)
                 .x(d3.scaleTime().domain([new Date(2011, 0, 1), new Date()]))
                 .xUnits(() => {return (new Date()).getUTCFullYear() - 2011;})
                 .dimension(dim)
                 .round(d3.timeYear)
                 .centerBar(true)
                 .barPadding(0.5)
                 .group(dim.group(d3.timeYear));

            el.chart.yAxis().ticks(2);
            el.chart.render();
            new ResizeObserver(() => {
              el.chart.width(el.width)
                   .height(el.clientHeight)
                   .render();
            }).observe(el);
          }
        }

        return el.chart // Return the chart object to the template component
    }
}

export const BarChart = props => (
    /*
        Call the ChartTemplate Component with the chartFunction
    */
      
    <ChartTemplate chartFunction={barChartFn(props)} {...props.styles} className={props.className} />
)