import React from "react";
import * as dc from "dc";
import { ChartTemplate } from "../charttemplate";

const rowChartFn = (props) => {
    const val = props;
    return (divRef, ndx) => {
        const el = divRef;
        console.log('ndx:', ndx);
        window.ndx = ndx;
        if(!el.chart) {
          el.style.overflowX = 'hidden';
          if(ndx) {
            console.log(dc);
            el.chart = new dc.RowChart(el);
            let dim = ndx.dimension(val.field, val.isArray);
            function reduceAdd(p, v, nf) {
              for(let y = v.startDate.getUTCFullYear(); y <= v.endDate.getUTCFullYear(); y++) {
                if(!p.yearMap[y]) {
                  p.yearMap[y] = 0;
                }
                p.yearMap[y]++;
              }
              let newNum = new Number(Object.keys(p.yearMap).length);
              newNum.yearMap = p.yearMap;
              return newNum;
            }
            function reduceRemove(p, v, nf) {
              for(let y = v.startDate.getUTCFullYear(); y <= v.endDate.getUTCFullYear(); y++) {
                p.yearMap[y]--;
                if(p.yearMap[y] <= 0) {
                  delete p.yearMap[y];
                }
              }
              let newNum = new Number(Object.keys(p.yearMap).length);
              newNum.yearMap = p.yearMap;
              return newNum;
            }
            function reduceInit() {
              let p = new Number(0);
              p.yearMap = {};
              return p;
            }

            let group = dim.group().reduce(reduceAdd, reduceRemove, reduceInit)
            let barHeight = 15;
            let countHeight = barHeight + 5;

            el.chart.width(null)
                 .fixedBarHeight(barHeight)
                 .height(Math.max(60 + countHeight*group.size(), val.minHeight))
                 .dimension(dim)
                 .group(group);

            el.chart.xAxis().ticks(2);

            el.chart.render();

            function onResize() {
              el.chart.width(null)
                   .height(Math.max(60 + countHeight*group.size(), val.minHeight))
                   .redraw();
            }
            new ResizeObserver(onResize).observe(el);
          }
        }
        if(el.chart && val.colors) {
          el.chart.ordinalColors(val.colors);
          el.chart.redraw();
        }

        return el.chart // Return the chart object to the template component
    }
}

export const RowChart = props => (
    /*
        Call the ChartTemplate Component with the chartFunction
    */
      
    <ChartTemplate chartFunction={rowChartFn(props)} {...props.styles} className={props.className} />
)